.panel {
  background-color: #ffffff;
}

.app-add-casino__form-section {
}

.app-add-casino__form-panel {
}

.app-add-casino__form-panel--notification {
  margin-bottom: 20px;
}

.app-add-casino__form-field {
  width: 100%;
}
